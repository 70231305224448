const founders = [
  {
    name: "Ali Khan",
    designation: "Chief Executive Officer",
    img: "ali",
    desc: "Ali Khan is a healthcare and connectivity executive and entrepreneur. He is known for founding, developing, and delivering transformative ventures that scale globally, despite the odds.\n\nAli's experience spans over two decades, most recently developing and growing the global insurer AXA's flagship well-being services as CTO and Chief Science Innovation Officer.\n\nAli is also an avid investor, and advisor to multiple start-ups, corporate and academic institutions. Father of three, he loves cinema, long walks and paying it forward. And has yet to make it to two more continents!",
    linkedIn: "https://www.linkedin.com/in/alikhan1/",
  },
  {
    name: "Dr. John Lang",
    designation: "Chief Scientific Officer",
    img: "john",
    desc: "John Lang, PhD, a seasoned leader in employee wellbeing for nearly 40 years, spearheaded the establishment of the Australian industry body and led it for six years.\n\nHe successfully steered multiple organisations as MD or CEO to become market leaders in Australia from inception to acquisition. With a background in mathematics and medicine, John's health risk prediction algorithms power health impact assessments worldwide.\n\nJohn lives on the beach in South Sydney, loves travelling and rather big, complex spreadsheet problems with endless columns of data!",
    linkedIn: "https://www.linkedin.com/in/john-g-lang/",
  },
]

const team = [
  {
    name: "Gordon Watson",
    designation: "Chairman, AXA Asia",
    img: "gordon",
    desc: "“Excited to contribute to SHAPE's mission, leveraging science to measurably advance stakeholder welfare and improve workplace culture.”",
    linkedIn: "https://www.linkedin.com/in/gordontwatson/",
  },
  {
    name: "Ken Hoskin",
    designation: "Head of APAC HR, Adyen (Formerly at Meta, Airbnb)",
    img: "ken",
    desc: "“Excited being part of this thoroughly upleveled approach with SHAPE’s robust science, focus on actual experiences, and achieving the aspirational goal of flourishing.”",
    linkedIn: "https://www.linkedin.com/in/kenhoskin/",
  },
  {
    name: "Nicola Thompson",
    designation: "Non Executive Director (Formerly CEO MADE.com)",
    img: "nicola",
    desc: "“SHAPE resolves the Employee Experience Paradox with data, insightful analysis, and actionable feedback.”",
    linkedIn: "https://www.linkedin.com/in/nicola-thompson-29169323/",
    memo: true,
  },
  {
    name: "Craig Moss",
    designation: "EVP, Ethisphere",
    img: "craig",
    desc: "“Thrilled to fuel organisational transformation with SHAPE's innovative and scientifically grounded approach.”",
    linkedIn: "https://www.linkedin.com/in/craig-moss-90104010/",
  },
  {
    name: "Doug Hudson",
    designation: "Managing Partner, Braxton Capital",
    img: "doug",
    desc: "“I’m fortunate to be part of such an innovative platform focused on enhancing employee wellbeing and accelerating growth.”",
    linkedIn: "https://www.linkedin.com/in/douglaswhudson/",
  },
]

export { founders, team }
